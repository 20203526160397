<template>
  <el-form ref="form" :model="serviceForm" label-width="96px" class="wrap" v-loading="loading" :rules="rules" id="wrap">
    <!-- 顶部按钮 -->
    <topOperatingButton :isSubmitBtn='serviceForm.goodsType!=3' :isSubmitAddBtn='serviceForm.goodsType!=3'
      :isAuditBillBtn='serviceForm.goodsType!=3' :disabled="disabled" :showAudit="showAudit" :isAddBtn="showAdd"
      @submitForm="submitForm(true)" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd" @getQuit="getQuit"
      id="topOperatingButton">
      <template #specialDiyBtn>
        <el-button type="success" @click="submitProcess()" size="mini" :disabled="serviceForm.bomStatus != '2'">保存工序
        </el-button>
      </template>
    </topOperatingButton>
    <div class="x-f">
      <!-- 商品数据左边盒子 -->
      <categoryTreeCard categoryTreeTitle="产品配方结构" @treeNodeId="getTreeNodeId"
        :categoryTreeOptions="categoryTreeOptions" :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight" />
      <!-- 基本信息右边盒子 -->
      <div class="rightBox y-b" v-loading="loadingGoods">
        <!-- 表格搜索条件 -->
        <cardTitleCom cardTitle="基本信息" :billStatus="serviceForm.bomStatus" id="search-card">
          <template slot="cardContent">
            <div class="x-w marT10">
              <!-- 商品信息 -->
              <!-- 第一行 -->
              <el-form-item label="配方编码" prop="bomNo">
                <el-input class="inputWidth" disabled v-model="serviceForm.bomNo" placeholder="配方编码"
                  size="mini"></el-input>
              </el-form-item>
              <el-form-item label="商品编码" prop="goodsId">
                <SelectRemote @selectChange="selectChangeGoods" v-model="serviceForm.goodsId" :option="
                    $select({
                      key: 'listBomGoods',
                      option: {
                        option: {
                          disabled: disabled||serviceForm.goodsType==3,
                            showItem: [
                          {
                            goodsId: serviceForm.goodsId,
                            goodsName: serviceForm.goodsNo,
                          },
                        ],
                        }
                      }
                    }).option
                  " />
              </el-form-item>
              <el-form-item label="商品名称" prop="goodsName">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model="serviceForm.goodsName"
                  placeholder="商品名称"></el-input>
              </el-form-item>
              <el-form-item label="规格型号" prop="goodsSpec">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model="serviceForm.goodsSpec"
                  placeholder="规格型号"></el-input>
              </el-form-item>
              <el-form-item label="版本号" prop="bomVer">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model="serviceForm.bomVer"
                  placeholder="版本号"></el-input>
              </el-form-item>
              <el-form-item label="单位" prop="unitId">
                <el-select size="mini" v-model="serviceForm.unitId" :disabled="true" class="inputWidth">
                  <el-option v-for="item in serviceForm.units" :key="item.unitId" :label="item.unitName"
                    :value="item.unitId" />
                </el-select>
              </el-form-item>
              <el-form-item label="成品率(%)" prop="yieldRate">
                <el-input size="mini" class="inputWidth" :disabled="disabled||serviceForm.goodsType==3"
                  v-model="serviceForm.yieldRate" placeholder="成品率"></el-input>
              </el-form-item>
              <el-form-item label="批产量" prop="batchProdQty">
                <el-input size="mini" class="inputWidth" :disabled="disabled||serviceForm.goodsType==3"
                  @keyup.native="onkeyupBatchProdQty" v-model="serviceForm.batchProdQty" placeholder="批产量"></el-input>
              </el-form-item>
              <el-form-item label="成本价" prop="oneCostMoney">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model.number="serviceForm.oneCostMoney"
                  placeholder="成本价"></el-input>
              </el-form-item>
              <el-form-item label="批量成本" prop="batchCostMoney">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model.number="serviceForm.batchCostMoney"
                  placeholder="批量成本"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input size="mini" class="inputRemark" :disabled="disabled||serviceForm.goodsType==3"
                  v-model="serviceForm.remark" type="textarea" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }" />
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!-- 表格商品信息卡片 -->
        <cardTitleCom :cardTitle="serviceForm.bomId?null:'基本信息'" class="headGoodsTable">
          <template slot="leftCardTitle" v-if="serviceForm.bomId">
            <el-radio-group v-model="tabType" size="mini">
              <el-radio-button :label="1">子件信息</el-radio-button>
              <el-radio-button :label="2">配方工序</el-radio-button>
            </el-radio-group>
          </template>
          <template slot="cardContent">
            <!-- 商品信息按钮和表格整体盒子 -->
            <div class="goodsMessage" v-if="serviceForm.goodsType!=3">
              <el-table v-show="tabType==1" ref="multipleTable1" :data="serviceForm.bomDetail" border
                @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" :max-height="tableHeight"
                :height="tableHeight">
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="商品编码" min-width="180" align="center" prop="goodsNo">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>商品编码</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.goodsNo'"
                      :rules="rules[`bomDetail.goodsNoMaterial`]">
                      <el-input maxlength="20" size="mini" v-model="scope.row.goodsNo" :disabled="disabled"
                        @keyup.enter.native="
                          keyup('goodsNo', $event, scope.$index)
                        " @click.native="selectGoods">
                        <i slot="suffix" class="el-icon-more more"></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="商品名称" align="center" prop="goodsName" show-overflow-tooltip min-width="120">
                </el-table-column>
                <el-table-column label="规格" align="center" prop="goodsSpec" show-overflow-tooltip min-width="100">
                </el-table-column>
                <el-table-column label="版本号" align="center" prop="bomVer" show-overflow-tooltip min-width="80">
                </el-table-column>
                <el-table-column label="单位" align="center" prop="unitId" min-width="120">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单位</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.unitId'"
                      :rules="rules[`bomDetail.unitIdMaterial`]">
                      <el-select size="mini" @change="unitPriceChange($event, scope.row)" v-model="scope.row.unitId"
                        :disabled="disabled">
                        <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                          :value="item.unitId" />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="单个用量" align="center" min-width="120" prop="oneUseQty">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单个用量</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.oneUseQty'"
                      :rules="rules[`bomDetail.oneUseQtyMaterial`]">
                      <el-input maxlength="15" size="mini" @keyup.native="onkeyupOneUseQty(scope.row)"
                        v-model="scope.row.oneUseQty" :disabled="disabled"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="成本价" align="center" show-overflow-tooltip prop="oneCostPrice" min-width="120" />
                <el-table-column label="成本" align="center" show-overflow-tooltip prop="oneCostMoney" min-width="120">
                </el-table-column>
                <el-table-column label="单批产量用量" align="center" prop="batchUseQty" min-width="120">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单批产量用量</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.batchUseQty'"
                      :rules="rules[`bomDetail.batchUseQtyMaterial`]">
                      <el-input maxlength="15" size="mini" @keyup.native="onkeyupBatchUseQty(scope.row)"
                        v-model="scope.row.batchUseQty" :disabled="disabled"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="批量成本" align="center" prop="batchCostMoney" min-width="120" />
                <el-table-column label="损耗率(%)" align="center" prop="loseRate" min-width="100">
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.loseRate'"
                      :rules="rules[`bomDetail.loseRateMaterial`]">
                      <el-input size="mini" v-model="scope.row.loseRate" :disabled="disabled" maxlength="8"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="备注" align="center" prop="remark" min-width="155">
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.remark" :disabled="disabled" maxlength="80"></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <el-table v-show="tabType==2" ref="multipleTable2" :data="bomProcessSettingList" border
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column label="序号" align="center" width="80" type="index" />
                <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="120" />
                <el-table-column label="商品名称" align="center" prop="goodsName" min-width="120" />
                <el-table-column label="规格" align="center" prop="goodsSpec" min-width="100" />
                <el-table-column label="单位" align="center" prop="unitName" min-width="80" />
                <template
                  v-for="(item,index) in (bomProcessSettingList.length>0&&bomProcessSettingList[0].list?bomProcessSettingList[0].list:[])">
                  <el-table-column :label="item.processInfo.processName" align="center" width="200"
                    :key="`${index},${item.processInfo.processId},${getUid()}`">
                    <el-table-column align="center" width="80">
                      <template slot="header">
                        <div>
                          启用
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.list[index].isUse" :disabled="serviceForm.bomStatus != '2'"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" width="80">
                      <template slot="header">
                        <div>
                          生产
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.list[index].isProduce" :disabled="serviceForm.bomStatus != '2'"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" width="80">
                      <template slot="header">
                        <div>
                          交接
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.list[index].isHandover" :disabled="serviceForm.bomStatus != '2'"></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </template>
        </cardTitleCom>
      </div>
    </div>
    <!-- 多选选择商品信息框 -->
    <SelectGoods ref="selectGoods" :OpenGoods.sync="OpenGoods" :queryCode="queryCode" @getGoodS="getGoodS">
    </SelectGoods>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-form>
</template>

<script>
import SelectGoods from '@/views/components/selectGoodsUp' //选择商品
import { listGoods } from '@/api/goods/goods' //商品列表
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { getBillNo } from '@/api/codeRule' //单据号
import {
  serviceAddAPI,
  serviceDetailAPI,
  serviceUpdateAPI,
  serviceGoodsTreeAPI,
  serviceUpdateStatusAPI,
  getBomProcessSetting,
  saveBomProcess
} from '@/api/produce/bom/service' //生产配方接口
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import Dialog from '@/components/Dialog'
import { deepCopy, getUid } from '@/utils'

export default {
  name: 'serviceDetail',
  dicts: ['App_is_use', 'bill_status'],
  components: {
    SelectGoods,
    categoryTreeCard,
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    Dialog
  },
  data() {
    return {
      tabType: 1,
      bomProcessSetting: {}, //获取配方的工序
      bomProcessSettingList: [], //获取配方的表格工序
      //弹窗配置
      dialogOptions: {
        title: '选择商品',
        width: 1250,
        show: false,
        type: 'TreeAndTable',
        formData: this.$dialog({ key: 'goods' })
      },
      tableCellLabel: '序号',
      isSelectGoodsRadio: false, //商品弹框单选
      indexTreeId: undefined, //第一次的bomId
      indexTree: 0, //当前bom级别
      categoryTreeOptions: [], //树的数据
      //自定义搜索框字段转换 (供应商)
      normalizerGoods: {
        Id: 'goodsId',
        No: 'goodsNo',
        Name: 'goodsName'
      },
      oldForm: {}, //旧的表单
      disabled: false, //表单禁用
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //选择商品信息开关 多选
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      // 遮罩层
      loading: false,
      //商品遮罩
      loadingGoods: false,
      // 表单校验
      rules: {
        bomNo: [
          {
            required: true,
            message: '配方编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        goodsId: [
          {
            required: true,
            message: '商品编码不能为空',
            trigger: ['blur', 'change']
          } //仓库地址
        ],
        unitId: [
          {
            required: true,
            message: '商品单位不能为空',
            trigger: ['blur', 'change']
          } //负责人
        ],
        yieldRate: [
          {
            required: true,
            message: '商品成品率不能为空',
            trigger: ['blur', 'change']
          },
          { pattern: /^\d{0,3}(\.\d{0,2})?$/, message: '超出输入字符范围' }
        ],
        batchProdQty: [
          {
            required: true,
            message: '商品批产量不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,6}(\.\d{0,4})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.goodsNoMaterial': [
          {
            required: true,
            message: '原料编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'bomDetail.unitIdMaterial': [
          {
            required: true,
            message: '原料单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'bomDetail.oneUseQtyMaterial': [
          {
            required: true,
            message: '原料用量不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,6}(\.\d{0,6})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.batchUseQtyMaterial': [
          {
            required: true,
            message: '批产量用量不为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,6}(\.\d{0,6})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.loseRateMaterial': [
          {
            pattern: /^\d{0,3}(\.\d{0,2})?$/,
            message: '超出输入字符范围',
            trigger: ['blur', 'change']
          }
        ]
      },
      // 表单参数
      serviceForm: {
        bomStatus: '',
        bomDetail: [{}]
      },
      vivwH: 0, //页面高度
      searchH: 0 //搜索框高度
    }
  },
  watch: {
    //侦听是否开启关闭按钮
    'serviceForm.bomStatus': {
      handler(newVal) {
        if (this.serviceForm.bomStatus == '2') {
          //引入源单按钮
          this.showImportBill = false
          //新增按钮
          this.showAdd = true
          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = false
        } else if (this.serviceForm.bomStatus == '3') {
          //引入源单按钮
          this.showImportBill = false
          //新增按钮
          this.showAdd = false

          //禁用审核按钮
          this.showAudit = true
          //禁止全部输入按钮
          this.disabled = true
        } else if (this.serviceForm.bomStatus == '0') {
          //引入源单按钮
          this.showImportBill = false
          //新增按钮
          this.showAdd = false

          //禁用审核按钮
          this.showAudit = false

          //恢复全部输入
          this.disabled = false
        } else if (this.serviceForm.bomStatus == '4') {
          //引入源单按钮
          this.showImportBill = false
          //新增按钮
          this.showAdd = false

          //禁用审核按钮
          this.showAudit = false

          //恢复全部输入
          this.disabled = false
        } else if (this.serviceForm.bomStatus == '') {
          //引入源单按钮
          this.showImportBill = true
          //新增按钮
          this.showAdd = false

          //禁用审核按钮
          this.showAudit = true
          //恢复全部输入
          this.disabled = false
        }
      },
      immediate: true
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 访问组件实例 vm 中的数据和方法
      vm.reset()
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.bomId) {
          //获取单据详情
          const res1 = await serviceDetailAPI(vm.$route.query.bomId)
          //单据赋值
          vm.serviceForm = res1.data
          vm.indexTree += 1
          vm.indexTreeId = vm.$route.query.bomId
          //配方树
          const res2 = await serviceGoodsTreeAPI(vm.$route.query.bomId)
          vm.categoryTreeOptions = res2
          //获取配方的工序
          const res3 = await getBomProcessSetting(vm.$route.query.bomId)
          vm.bomProcessSetting = res3.data
          vm.bomProcessSettingList = vm.bomProcessSetting?.goodsBoms?.map(x => {
            let arr = vm.bomProcessSetting?.bomProcesses?.filter(v => v.bomId == x.bomId)
            let craftLineProcesses = vm.bomProcessSetting?.craftLineGoods?.craftLineProcesses ? deepCopy(vm.bomProcessSetting.craftLineGoods.craftLineProcesses) : []
            x.list = craftLineProcesses.map(y => {
              let obj = arr.find(z => (z.craftLineProcessLineId == y.lineId))
              if (obj) {
                y.isUse = obj.isUse
                y.isHandover = obj.isHandover
                y.isProduce = obj.isProduce
              }
              return {
                isUse: false, isHandover: false, isProduce: false,
                ...y
              }
            })
            return {
              ...x,
              ...vm.bomProcessSetting.craftLineGoods,
              // ...obj,
            }
          })
          console.log('bomProcessSettingList', vm.bomProcessSettingList);
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.serviceForm))
        }
      } else {
        //获取单据编码
        await vm.getOrderBillNo()
        vm.disabled = false
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.serviceForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.serviceForm))
      }
      vm.loading = false
    })
  },

  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.serviceForm.bomId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.serviceForm))
    }
    next()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.searchH - 105
    },
    boxHeight() {
      return this.vivwH - 40
    },
    boxInternalHeight() {
      return this.vivwH - 68
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getUid,
    //保存工序
    async submitProcess() {
      console.log('bomProcessSettingList1', this.bomProcessSettingList);
      let arr = deepCopy(this.bomProcessSetting.goodsBoms)
      let array = []
      arr.forEach(v => {
        let arr2 = v.list.filter(x => x.isUse || x.isHandover || x.isProduce)
        arr2.forEach(x => {
          array.push({
            craftLineId: x.craftLineId,
            craftLineProcessLineId: x.lineId,
            processId: x.processId,
            processName: x.processName,
            isUse: x.isUse,
            isHandover: x.isHandover,
            isProduce: x.isProduce,
            bomId: v.bomId,
            parentBomId: v.parentBomId,
            rootBomId: v.rootBomId,
            goodsId: v.goodsId,
            goodsName: v.goodsName,
            unitId: v.unitId,
          })
        })
      })
      await saveBomProcess(array)
      this.$message({
        type: 'success',
        message: '保存工序成功'
      })
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case 'dialogChange':
          //商品
          this.getGoodS(this.dialogOptions.formData.table.check)
          break
        default:
          break
      }
    },
    selectChangeGoods(val, value) {
      this.$set(this.serviceForm, 'goodsId', value?.goodsId)
      this.$set(this.serviceForm, 'goodsNo', value?.goodsNo)
      this.$set(this.serviceForm, 'goodsName', value?.goodsName)
      this.$set(this.serviceForm, 'goodsSpec', value?.goodsSpec)
      this.$set(this.serviceForm, 'unitId', value?.unitId)
      this.$set(this.serviceForm, 'units', value?.units)
      //批产量
      if (!this.serviceForm.batchProdQty) {
        this.$set(this.serviceForm, 'batchProdQty', 1)
      }
      //成品率
      if (!this.serviceForm.yieldRate) {
        this.$set(this.serviceForm, 'yieldRate', 100)
      }
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.serviceForm.bomStatus == '0' ||
        this.serviceForm.bomStatus == ''
      ) {
        let eleIndex = this.serviceForm.bomDetail.indexOf(row)
        for (
          let index = 0;
          index < this.serviceForm.bomDetail.length;
          index++
        ) {
          const element = this.serviceForm.bomDetail[index]
          if (eleIndex == index) {
            element['hoverRow'] = true
          } else {
            element['hoverRow'] = false
          }
        }
        this.tableCellLabel = '操作'
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.serviceForm.bomDetail.length; index++) {
        const element = this.serviceForm.bomDetail[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //新增按钮
    async handleAdd() {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      this.loading = false
    },
    //成品批量成本 计算公式
    totalCostBatch() {
      let num = 0
      let arr = []
      this.serviceForm.bomDetail.forEach(v => {
        if (v.batchCostMoney >= 0) {
          v.batchCostMoney = parseFloat(v.batchCostMoney)
          arr.push(v.batchCostMoney)
        }
      })
      num = arr.reduce((acc, curr) => acc + curr, 0)
      num = this.formatNumber(num)
      this.$set(this.serviceForm, 'batchCostMoney', num)
    },
    //成品成本 计算公式
    totalCost() {
      let num = 0
      let arr = []
      this.serviceForm.bomDetail.forEach(v => {
        if (v.oneCostMoney >= 0) {
          v.oneCostMoney = parseFloat(v.oneCostMoney)
          arr.push(v.oneCostMoney)
        }
      })
      num = arr.reduce((acc, curr) => acc + curr, 0)
      num = this.formatNumber(num)
      this.$set(this.serviceForm, 'oneCostMoney', num)
    },

    //批产量*用量=批产量用量  批产量成本  计算公式
    batchProductionYes() {
      if (this.serviceForm.bomDetail.length > 0 && this.serviceForm.bomDetail) {
        this.serviceForm.bomDetail.forEach(v => {
          if (this.serviceForm.batchProdQty >= 0 && v.oneUseQty >= 0) {
            let batchUseQty = v.oneUseQty * this.serviceForm.batchProdQty
            batchUseQty = this.formatNumber(batchUseQty, 6)
            this.$set(v, 'batchUseQty', batchUseQty)
          }
          //原料批产量成本
          if (v.batchUseQty >= 0 && v.oneCostPrice >= 0) {
            let batchCostMoney = v.batchUseQty * v.oneCostPrice
            batchCostMoney = this.formatNumber(batchCostMoney)
            this.$set(v, 'batchCostMoney', batchCostMoney)
          }
        })
      }
    },
    //批产量用量/批产量=用量  计算公式
    batchProductionNo() {
      if (this.serviceForm.bomDetail.length > 0 && this.serviceForm.bomDetail) {
        this.serviceForm.bomDetail.forEach(v => {
          if (this.serviceForm.batchProdQty >= 0 && v.batchUseQty >= 0) {
            let oneUseQty = v.batchUseQty / this.serviceForm.batchProdQty
            oneUseQty = this.formatNumber(oneUseQty, 6)
            this.$set(v, 'oneUseQty', oneUseQty)
          }
          //原料成本
          if (v.oneUseQty >= 0 && v.oneCostPrice >= 0) {
            let oneCostMoney = v.oneUseQty * v.oneCostPrice
            oneCostMoney = this.formatNumber(oneCostMoney)
            this.$set(v, 'oneCostMoney', oneCostMoney)
          }
        })
      }
    },
    //退出
    async getQuit() {
      if (!this.indexTreeId) {
        // 关闭当前tab页签，打开新页签
        this.$tab.closeOpenPage({ path: '/produce/bom/service' })
        return
      }
      const res = await serviceDetailAPI(this.indexTreeId)
      if (res.data.bomStatus == '0') {
        let goodsName = this.categoryTreeOptions[0].label
        this.$confirm(`${goodsName}还未审核, 是否审核?`, '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const obj = {
              bomIds: [this.indexTreeId],
              bomStatus: '2'
            }
            //发送审核api
            serviceUpdateStatusAPI(obj)
            // 弹出提示
            this.$message.success('审核成功')
            // 关闭当前tab页签，打开新页签
            this.$tab.closeOpenPage({ path: '/produce/bom/service' })
          })
          .catch(() => {
            // 关闭当前tab页签，打开新页签
            this.$tab.closeOpenPage({ path: '/produce/bom/service' })
          })
      } else if (res.data.bomStatus != '0') {
        // 关闭当前tab页签，打开新页签
        this.$tab.closeOpenPage({ path: '/produce/bom/service' })
      }
    },
    //单批产量用量键盘抬起
    onkeyupBatchUseQty(row) {
      //单批产量用量
      console.log('单批产量用量', row.batchUseQty, row.oneCostPrice);
      if (row.batchUseQty >= 0 && row.oneCostPrice >= 0) {
        let batchCostMoney = row.batchUseQty * row.oneCostPrice
        batchCostMoney = this.formatNumber(batchCostMoney)
        this.$set(row, 'batchCostMoney', batchCostMoney)
      }
      //批产量用量/批产量=用量
      this.batchProductionNo()
      //成品成本
      this.totalCost()
      //成品批产量成本
      this.totalCostBatch()
    },
    //原料用量键盘抬起
    onkeyupOneUseQty(row) {
      if (row.oneUseQty >= 0 && row.oneCostPrice >= 0) {
        let oneCostMoney = row.oneUseQty * row.oneCostPrice
        oneCostMoney = this.formatNumber(oneCostMoney)
        this.$set(row, 'oneCostMoney', oneCostMoney)
      }
      //批产量*用量=批产量用量
      this.batchProductionYes()
      //成品成本
      this.totalCost()
      //成品批产量成本
      this.totalCostBatch()
    },
    //成品批产量键盘抬起
    onkeyupBatchProdQty() {
      //批产量*用量=批产量用量
      this.batchProductionYes()
      //成品批产量成本
      this.totalCostBatch()
    },
    //多级单位换算
    unitPriceChange(value, row) {
      row.units.forEach(v => {
        if (v.unitId === value) {
          this.$set(row, 'oneCostPrice', v.unitPrice)
          const oneCostMoney = row.oneUseQty * row.oneCostPrice
          this.$set(row, 'oneCostMoney', oneCostMoney)
          const batchCostMoney = row.batchUseQty * row.oneCostPrice
          this.$set(row, 'batchCostMoney', batchCostMoney)
        }
      })
    },
    //点击类别树
    async getTreeNodeId(value) {
      this.loadingGoods = true
      //获取单据详情
      const res1 = await serviceDetailAPI(value)
      //单据赋值
      this.serviceForm = res1.data
      await this.getOrderBillNo()
      //判断原料是否为空
      if (
        (this.serviceForm.bomDetail.length <= 0 ||
          this.serviceForm.bomDetail == undefined) &&
        this.serviceForm.goodsType != '3'
      ) {
        this.serviceForm.bomDetail = [{}]
      }
      //判断配方编码是不是为空
      // if (!this.serviceForm.bomNo) {
      //   this.$set(this.serviceForm, 'yieldRate', 100)
      // }
      //原始单据赋值
      this.oldForm = JSON.parse(JSON.stringify(this.serviceForm))
      this.loadingGoods = false
    },
    //选择商品方法
    selectGoods() {
      this.dialogOptions.formData.table.pagination.goodsTypes = [2, 3]
      this.dialogOptions.show = true
    },
    //选中的商品
    getGoodS(value) {
      //去重
      value = value.filter(item => {
        return this.serviceForm.bomDetail.every(v => v.goodsId != item.goodsId)
      })
      value.forEach(v => {
        this.$set(v, 'unitQty', 0)
        this.$set(v, 'unitMoney', 0)
        this.$set(v, 'loseRate', 0)
        this.$set(v, 'unitPrice', v.purPrice)
        this.$set(v, 'oneCostPrice', v.purPrice)
        v.remark = ''
        v.unitMoney = v.unitQty * v.unitPrice
      })
      this.serviceForm.bomDetail.push(...value)
      this.serviceForm.bomDetail = this.serviceForm.bomDetail.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
    },

    //表格单元格回车事件
    keyup(name, event, index) {
      //判断是否输入编码或条码
      if (this.serviceForm.bomDetail[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.serviceForm.bomDetail[`${index}`][`${name}`],
          goodsTypes: [2, 3]
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //去重
            let isRepeat = this.serviceForm.bomDetail.every(
              item => item.goodsId != response.rows[0].goodsId
            )
            if (!isRepeat) {
              this.$message.error('当前表格已有此商品!')
              return
            }
            //原始赋值
            response.rows[0].remark = ''
            response.rows[0].unitQty = 0
            response.rows[0].unitMoney = 0
            response.rows[0].loseRate = 0
            response.rows[0].unitPrice = response.rows[0].purPrice
            response.rows[0].oneCostPrice = response.rows[0].purPrice
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.serviceForm.bomDetail, index, response.rows[0])
          } else {
            this.dialogOptions.formData = this.$dialog({ key: 'goods' })
            this.dialogOptions.formData.table.pagination.query =
              this.serviceForm.bomDetail[`${index}`][`${name}`]
            this.dialogOptions.formData.table.pagination.goodsTypes = [2, 3]
            this.dialogOptions.show = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.dialogOptions.formData = this.$dialog({ key: 'goods' })
        this.dialogOptions.formData.table.pagination.goodsTypes = [2, 3]
        this.dialogOptions.show = true
      }
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === 'push') {
        this.serviceForm.bomDetail.splice(index + 1, 0, {})
      } else {
        if (this.serviceForm.bomDetail.length <= 1) {
          this.serviceForm.bomDetail = [{}]
        } else {
          this.serviceForm.bomDetail.splice(index, 1)
        }
      }
    },

    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.serviceForm.bomStatus != '0') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            bomIds: [this.serviceForm.bomId],
            bomStatus: '2'
          }
          //发送审核api
          const { data } = await serviceUpdateStatusAPI(obj)
          this.serviceForm = data
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '反审核') {
          if (this.serviceForm.bomStatus != '2') {
            this.$message.error('此单据不是已审核的状态哦~')
            return
          }
          const obj = {
            bomIds: [this.serviceForm.bomId],
            bomStatus: '0'
          }
          //发送反审核api
          const { data } = await serviceUpdateStatusAPI(obj)
          this.serviceForm = data
          // 弹出提示
          this.$message.success('反审核成功')
        }
      } catch { }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm('是否保存并新增单据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          this.loading = true
          await this.submitForm(false)
          this.loading = false
        })
        .catch()
    },

    async getOrderBillNo() {
      //新增时获取单据编码
      const res = await getBillNo(170101)
      this.$set(this.serviceForm, 'bomNo', res)
    },
    // 表单重置
    reset() {
      this.indexTreeId = undefined
      this.indexTree = 0
      this.categoryTreeOptions = [] //树的数据
      this.serviceForm = {
        bomStatus: '',
        bomDetail: [{}]
      }
      this.resetForm('form')
    },

    /** 提交按钮 */
    submitForm: function (isBool) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            if (this.serviceForm.bomId != undefined) {
              const res = await serviceUpdateAPI(this.serviceForm)

              if (isBool) {
                //重新赋值给表单
                this.serviceForm = res.data
                this.$modal.msgSuccess('修改生产配方成功')
              } else {
                this.$modal.msgSuccess('保存并新增生产配方成功')
              }
            } else {
              const res = await serviceAddAPI(this.serviceForm)
              if (isBool) {
                //重新赋值给表单
                this.serviceForm = res.data
                this.$modal.msgSuccess('新增生产配方成功')
              } else {
                this.$modal.msgSuccess('保存并新增生产配方成功')
              }
            }
            if (isBool) {
              //类别树
              this.indexTree += 1
              if (this.indexTree == 1) {
                this.indexTreeId = this.serviceForm.bomId
              }
              const res = await serviceGoodsTreeAPI(this.indexTreeId)
              this.categoryTreeOptions = res
            } else {
              //保存并新增
              this.reset()
              await this.getOrderBillNo()
            }
          } finally {
            this.loading = false
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete() {
      if (this.delFlag) {
        this.$message({
          message: '勾选当中有已经删除的品牌',
          type: 'warning'
        })
        return
      }
      this.$modal
        .confirm('是否确认删除仓库名称为"' + this.name + '"的数据项？')
        .then(() => {
          return delStore(this.ids)
        })
        .then(() => {
          this.$modal.msgSuccess('删除成功')
          this.getList()
        })
        .catch(() => { })
    }
  }
}
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  width: 100%;
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 125px);
    width: calc(100% - 362px); //商品搜索条件
    .headGoodsTable {
      // height: calc(100% - 136px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
        ::v-deep .el-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
</style>
